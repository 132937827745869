import MenuBoard from '../../../components/MenuBoard';
import React from 'react';
import { BURGERS } from './../../../utils/items';
import MenuBoardSection from '../../../components/MenuBoardSection';
import LayoutMenuBoard from '../../../layouts/layout-menuboard';

export default function MenuBoard3() {
	return (
		<LayoutMenuBoard>
			<MenuBoard>
				<MenuBoardSection {...BURGERS} />
			</MenuBoard>
		</LayoutMenuBoard>
	);
}
